.pagination>li>a {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
}

.dataTables_info {
    font-size: 12px;
}

.btn-group {
    display: flex;
    width: auto;
}

.dz-message {
    margin: 0;
    font-size: 24px;
    font-family: 'Source Sans Pro',sans-serif;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}
.dropzone {
    border: none;
}

.ajax .content-wrapper {
    margin-left: 0;
}


.file-check {
    display: none;
    position: absolute;
}

li.form form {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
}


.users-list>li .user-image {
    border-radius: 0;
    max-width: 100%;
    height: 140px;

    background-position: center;
    background-size: cover;
}

.users-list>li i.fa {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.users-list>li img {
    border-radius: 0;
}

.users-list>li {
    padding: 0;
    margin: 10px;
    border: 1px solid #f4f4f4;
}

.users-list .file-box {
    background-color: #f4f4f4;
}

.users-list .user-image {
    background-color: #ffffff;
}

.users-list .user-name {
    display: block;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    font-weight: bold;
}

#user-url {
    background-image: none;
}




li.double-link>a {
    margin-left: 80%;
    display: block;
    padding-top: 10px;
}

li.double-link>a:first-of-type {
    margin-left: 0;
    float: left;
    width: 80%;
}

li.double-link {
    /*padding-top: 8px;*/
    height: 40px;
}


body.dragging, body.dragging * {
    cursor: move !important;
}

.dragged {
    position: absolute;
    opacity: 0.5;
    z-index: 2000;
}

.uploaded_files li.placeholder {
    position: relative;
}
.uploaded_files li.placeholder:before {
    position: absolute;
    /** Define arrowhead **/
}